<template>
  <div class="c-bitts-carousel">
    <div class="c-bitts-carousel__header">
      <slot
        name="header"
        v-bind="{
          currentCarouselPage,
          cardPages,
          paginationDisabled,
          onNext,
          onPrev,
        }"
      >
        <div v-if="customTitle">
          <slot name="title" />
        </div>
        <CarouselPagination
          v-bind="{ currentCarouselPage, cardPages, paginationDisabled }"
          :size="btnSize"
          @next="onNext"
          @prev="onPrev"
        />
      </slot>
    </div>
    <div class="c-bitts-carousel__items">
      <div
        ref="carouselList"
        class="c-bitts-carousel__list"
        data-testid="c-bitts-carousel"
        :style="{
          transform: `translateX(${currentXPosition}px)`,
        }"
      >
        <TransitionGroup name="c-bitts-carousel-transition">
          <BittsCard
            v-for="item in props.items"
            :key="item[props.uniqueKey]"
            class="c-bitts-carousel-card"
            :no-padding="false"
          >
            <BittsButton
              v-if="dismissable"
              :center-icon="['fas', 'x']"
              variant="ghost"
              class="c-bitts-carousel-card__dismiss"
              type="neutral"
              size="x-small"
              data-testid="dismiss-carousel-item"
              @click="$emit('dismiss', item)"
            />
            <slot name="carousel-card" :item="item" />
          </BittsCard>
        </TransitionGroup>
      </div>
      <template v-if="props.items.length === 0">
        <slot name="empty-state" />
      </template>
    </div>
    <div class="c-bitts-carousel__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from 'vue';

import BittsButton from '../BittsButton/BittsButton.vue';
import BittsCard from '../BittsCard/BittsCard.vue';
import CarouselPagination from '../BittsCarousel/CarouselPagination.vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  paginationDisabled: {
    type: Boolean,
    default: false,
  },
  gap: {
    type: Number,
    default: 8,
    validator: (val) => [8, 12, 16].includes(val),
  },
  uniqueKey: {
    type: String,
    required: true,
  },
  dismissable: {
    type: Boolean,
    default: true,
  },
  customTitle: {
    type: Boolean,
    default: false,
  },
  btnSize: {
    type: String,
    default: 'medium',
    validator: (size) => {
      return ['small', 'medium', 'large'].includes(size);
    },
  },
  width: {
    type: Number,
    default: 300,
  },
  useFullWidth: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['dismiss']);

const carouselList = ref(null);
const currentXPosition = ref(0);
const currentCarouselPage = ref(1);
const carouselListWidth = ref(null);
const xMargin = computed(() => `${props.gap}px`);
const marginOffset = computed(() => `${-props.gap}px`);

onMounted(() => {
  try {
    observer.observe(carouselList.value);
    onResize();
  } catch (err) {
    console.warn(err);
  }
});
onBeforeUnmount(() => observer.unobserve(carouselList.value));

const numCardPerPage = computed(() => {
  if (props.useFullWidth) return props.items.length;
  return Math.floor(carouselListWidth.value / props.width);
});
const cardPages = computed(() =>
  Math.ceil(props.items.length / numCardPerPage.value),
);

watch(
  () => cardPages.value,
  (_, oldVal) => {
    if (oldVal === currentCarouselPage.value) onPrev();
  },
);

const observer = new ResizeObserver(() => onResize());

const onPrev = () => {
  if (currentCarouselPage.value === 1) return;
  currentCarouselPage.value -= 1;
  currentXPosition.value += carouselListWidth.value;
};

const onNext = () => {
  if (currentCarouselPage.value === cardPages.value) return;
  currentCarouselPage.value += 1;
  currentXPosition.value -= carouselListWidth.value;
};

const onResize = async () => {
  currentCarouselPage.value = 1;
  currentXPosition.value = 0;
  await nextTick();
  carouselListWidth.value = carouselList.value?.clientWidth;
};
</script>

<style lang="pcss" scoped>
.c-bitts-carousel__footer {
  @apply flex items-center justify-end gap-16 mt-16;
}

.c-bitts-carousel__items {
  @apply overflow-x-hidden grow;
}

.c-bitts-carousel__list {
  @apply flex transition-all duration-300 ease-out;
  margin: 0px v-bind(marginOffset);
}

.c-bitts-carousel-card {
  flex: 0 0
    calc((100% / v-bind(numCardPerPage)) - v-bind(xMargin) - v-bind(xMargin));
  margin: 0px v-bind(xMargin);
  @apply p-16 relative;

  .c-bitts-carousel-card__dismiss {
    @apply absolute top-16 right-16;
  }
}
</style>
<style lang="pcss">
.c-bitts-carousel-transition-enter-active,
.c-bitts-carousel-transition-leave-active {
  transition: all 0.25s cubic-bezier(1, 0.5, 0.8, 1);
}
.c-bitts-carousel-transition-leave-to {
  @apply opacity-50 scale-0;
}
</style>
